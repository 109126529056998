import React, { Component } from "react";
import MainBox from "../molecules/MainBox";
import { getRGBAValue, getTimeDifference } from "@/Utils/Utils";
import { Avatar, Divider } from "@mui/material";
import BaseComponent from "../base/BaseComponent";
import { PostProps } from "@/types/ProfileTypes";
import { primaryText } from "@/styles/theme";
import DOMPurify from "dompurify";
import Link from "next/link";
import PostMenu from "./PostMenu";
import InfoPostBox from "./InfoPostBox";

/**
 * 주어진 텍스트에서 #으로 시작하는 키워드를 찾아 HTML로 링크를 생성하여 반환합니다.
 * @param text 변환할 텍스트
 * @returns HTML 문자열
 */
export const createHashtagLinksHTML = (text: string): string => {
  const words = text.split(/(\s+)/); // 공백으로 분리
  return words
    .map((word) => {
      if (word.startsWith("#")) {
        const tag = word.slice(1); // '#'을 제거한 후 키워드를 추출
        return `<a href="/search/${encodeURIComponent(tag)}">${word}</a>`;
      }
      return word; // 링크가 아닌 일반 텍스트 반환
    })
    .join(""); // 문자열로 다시 합치기
};

// Props 타입 정의
interface TextWithHashtagsProps {
  text: string;
}

class Post extends BaseComponent<
  { resData: PostProps; onClick?: any; type?: string },
  { isClicked?: boolean }
> {
  constructor(props: any) {
    super(props);

    this.state = {
      isClicked: false,
    };
  }
  //post id를 fetch하고, profile정보 fetch
  render() {
    const { resData } = this.props;
    const onClick = this.props.onClick;

    const TextWithHashtags: React.FC<TextWithHashtagsProps> = ({ text }) => {
      const htmlContent = createHashtagLinksHTML(text);

      return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
    };
    return (
      <div
        style={{
          width: "100%",
          height:
            this.props.type === "MAIN" && !this.state.isClicked
              ? "65px"
              : "auto",
          // height: "100%",
          padding: 0,
          backgroundColor: getRGBAValue("#f2ebf7", 0.8),
          margin: 0,
          overflowX: "hidden",
          overflowY: "hidden",
          //   borderRadius: "15px",
        }}
        onClick={onClick}
      >
        <PostMenu postId={resData.id} isMe={resData.isMe} />
        <div
          style={{
            display: "flex",
            width: "100%",
            // backgroundColor: getRGBAValue("#f2ebf7", 0.6),
            paddingTop: "5px",
            paddingBottom: "5px",
            // padding: "10px",
          }}
        >
          <div
            style={{
              width: "45px",
              display: "inline-block",
              padding: "4px",
              paddingLeft: "10px",
            }}
          >
            <a href={"/profile/" + resData.profileId}>
              <Avatar
                src={
                  resData.compactThumbnailUrl &&
                  resData.compactThumbnailUrl !== ""
                    ? resData.compactThumbnailUrl
                    : "/broken-image.jpg"
                }
                sx={{ width: 45, height: 45 }}
              />
            </a>
          </div>
          <div
            style={{
              alignItems: "left" /* 수직 중앙 정렬 */,
              display: "inline-block",
              width: "100%",
              //나머지영역 모두 채우기
              flexGrow: 1,
              justifyContent: "left",
              textAlign: "left",
              padding: "3px",
              paddingLeft: "15px",
              paddingBottom: "0px",
            }}
          >
            {/* {resData && resData.nickName !== undefined && (
              <span>
                <span style={{ color: primaryText }}>
                  <b>{resData.nickName}</b>
                </span>
              </span>
            )} */}
            <div
              style={{
                fontSize: "0.6rem",
                textAlign: "left",
                padding: "3px",
                color: "#4b4b4b",
              }}
            >
              <span style={{ color: primaryText }}>
                <b>{resData.nickName ? resData.nickName : ""}</b>
              </span>
              <span style={{ color: "#4d4d4d" }}>
                {resData.profileId
                  ? " @" +
                    resData.profileId +
                    " · " +
                    getTimeDifference(resData.createTime)
                  : ""}
              </span>
              {/* {resData.nickName ? resData.nickName : "GUEST"} /{" "}
                {resData.createTime.replace("T", " ").substring(0, 10)}{" "}
                {resData.createTime.replace("T", " ").substring(11, 16)} */}
            </div>
            <div
              style={{
                fontSize: "0.7rem",
                textAlign: "left",
                padding: "3px",

                whiteSpace:
                  this.props.type === "MAIN" && this.state.isClicked === false
                    ? "normal"
                    : "pre-wrap",

                //글자 줄임
                // whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <TextWithHashtags
                text={
                  this.props.type === "MAIN" && this.state.isClicked === false
                    ? resData.content.length >= 50
                      ? resData.content.slice(0, 40) + "..."
                      : resData.content
                    : resData.content
                }
              />
            </div>
          </div>
        </div>

        {((resData.type + "" !== "0" &&
          // resData.type + "" !== "3" &&
          !window.location.href.includes("/category/") &&
          !window.location.href.includes("/anime/") &&
          !window.location.href.includes("/product/") &&
          this.props.type + "" !== "MAIN") ||
          (this.props.type + "" === "MAIN" && this.state.isClicked)) && (
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              alignItems: "right",
              width: "100%",
              padding: "10px",
              paddingBottom: "10px",
              paddingTop: "0px",
            }}
          >
            <InfoPostBox
              type={resData.type + ""}
              infoCompactThumbnailUrl={
                resData.infoCompactThumbnailUrl
                  ? resData.infoCompactThumbnailUrl
                  : ""
              }
              infoTitle={resData.infoTitle ? resData.infoTitle : ""}
              infoContent={resData.infoContent ? resData.infoContent : ""}
              infoPrice={resData.infoPrice ? resData.infoPrice : ""}
              infoUrl={resData.infoUrl ? resData.infoUrl : ""}
            />
          </div>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            // paddingTop: "10px",
          }}
        >
          <Divider
            style={{
              width: "95%",
              height: "1px",
              backgroundColor: getRGBAValue("#f2ebf7", 0.6),
            }}
          />
        </div>
      </div>
    );
  }
}

export default Post;
