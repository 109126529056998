import React, { Component } from "react";
import BaseComponent from "../base/BaseComponent";
import Post from "../atoms/Post";
import { get } from "@/redux/module/api";

interface PostListProps {
  resData: Post[];

  isLoading: boolean;
  pageNumber: number;
}
class PostList extends BaseComponent<
  {
    type: string;
    profileId?: string;
    animeId?: string;
    productCategoryId?: string;
    productId?: string;

    onClick?: any;
  },
  PostListProps
> {
  constructor(props: any) {
    super(props);

    this.state = {
      resData: [],
      isLoading: true,
      pageNumber: 0,
    };
  }

  componentDidMount = async () => {
    super.componentDidMount();

    try {
      let res = null;
      if (this.props.type === "ALL") {
        res = await get("post/", {});
      } else if (this.props.type === "USER") {
        res = await get("post/" + this.props.profileId, {});
      } else if (this.props.type === "ANIME") {
        res = await get("post/anime/" + this.props.animeId, {});
      } else if (this.props.type === "PRODUCT_CATEGORY") {
        res = await get("post/category/" + this.props.productCategoryId, {});
      } else if (this.props.type === "PRODUCT") {
        res = await get("post/product/" + this.props.productId, {});
      } else if (this.props.type === "MAIN") {
        res = await get("post/all", {});
      }
      //   const res = await get("post/", {});

      if (res != null && res.status === 200) {
        const data = await res.data;

        this.setState({
          resData: data.resData,
          isLoading: false,
        });
      }
    } catch (e) {
      console.log(e);
    }
    if (this.props.type === "MAIN") {
      //   const interval = setInterval(async () => {
      //     window.scrollTo(0, window.scrollY + 65);
      //     //바닥까지 가면 다시 맨위로
      //     if (window.scrollY + window.innerHeight >= document.body.clientHeight) {
      //       window.scrollTo(0, 0);
      //     }
      //   }, 1000);
    }
  };

  render() {
    return (
      <>
        {this.state.resData && this.state.resData.length > 0 ? (
          this.state.resData.map((item: any, index: number) => {
            return (
              <Post
                resData={item}
                key={index}
                type={this.props.type}
                onClick={this.props.onClick ? this.props.onClick : null}
              />
            );
          })
        ) : this.props.type === "0" ? (
          <div>등록된 게시글이 없습니다.</div>
        ) : (
          <div
            style={{
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <span
              style={{
                fontFamily: "normal",
                fontSize: "0.7rem",
                // marginBottom: "20px",
              }}
            >
              등록된 게시글이 없습니다.
            </span>
          </div>
        )}
      </>
    );
  }
}

export default PostList;
