// components/BaseComponent.tsx
import { disableDevTools } from "@/Utils/Utils";
import React from "react";

interface BaseProps {}
interface BaseState {}

class BaseComponent<P = BaseProps, S = BaseState> extends React.Component<
  P,
  S
> {
  private cleanupDevTools: () => void = () => {};

  componentDidMount() {
    // 부모 클래스에서 개발자 도구 열림 방지 활성화
    this.cleanupDevTools = disableDevTools();
  }

  componentWillUnmount() {
    // 부모 클래스에서 개발자 도구 열림 방지 비활성화
    this.cleanupDevTools();
  }

  render() {
    return <div>Base Component</div>;
  }
}

export default BaseComponent;
